/* Support.css */
.support {
  display: flex;
  height: calc(100vh - 300px);
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.top-section {
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.support-img {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.info-section {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.info-item i {
  color: #f5222d;
  font-size: 24px;
  margin-right: 15px;
}

.info-text {
  font-size: 16px;
  line-height: 1.5;
}

.connect-us {
  text-align: center;
  margin-top: 30px;
}

.connect-us h6 {
  font-size: 20px;
  margin-bottom: 10px;
}

.social-icons span {
  font-size: 24px;
  margin: 0 10px;
  color: #ffffff;
}

.social-icons a {
  display: inline-block;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
}

.ant-typography {
  color: #af0000 !important;
  align-items: center;
  display: flex;
  gap: 8px;
}

@media (max-width: 768px) {
  .support-img {
    max-width: 100%;
  }

  .info-section {
    text-align: center;
  }

  .info-item {
    justify-content: center;
  }
}

/* Footer.css */
.connect-us {
  color: #af0000;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  text-align: center;
  transition: all 0.3s;
}

.social-link span {
  margin-left: 10px;
}

.icon {
  font-size: 30px;
}

@media only screen and (max-width: 768px) {
  .social-link {
    width: 100%;
    margin-bottom: 10px;
  }

  .icon {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .social-link {
    flex-direction: column;
    height: auto;
    padding: 0px !important;
  }

  .social-link span {
    margin-left: 0;
    margin-top: 10px;
  }

  .icon {
    margin-right: 0;
  }
}

.support_image {
  width: 100%;
  height: auto;
}

.support_user_details {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.details_text_div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.details_phone_value {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.contact_footer_div {
  background-color: #fff;
  padding: 20px 50px;
}

.contact_footer_row_1 {
  text-align: center;
  margin-bottom: 20px;
}

.details_phone_div {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.details_phone_text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact_footer_image {
  width: 165px;
  height: 109px;
}

@media (max-width: 749px) {
  .details_text_div {
    display: grid;
  }

  .details_phone_div {
    display: grid;
    gap: 0;
  }

  .contact_footer_row_1 {
    margin-bottom: 0 !important;
  }

  .contact_footer_div {
    padding: 0 !important;
  }

  .support {
    padding: 10px;
  }

  .contact_footer_image {
    width: 100%;
    height: 100%;
  }
}
