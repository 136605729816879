.user-form .ant-form-item-label {
  text-align: left;
}

.user-form .ant-form-item {
  margin-bottom: 0;
}

.user-form .ant-input-password .ant-input {
  padding: 4px 11px;
}

.user-form .ant-btn {
  margin-top: 10px;
}

.otherMail-input-field input {
  margin-left: 0px !important;
  padding: 0 10px !important;
}

.user_form_fields .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-24 {
  padding: 0 !important;
}

/* .ant-form-item-explain {
  display: none;
} */

@media (max-width: 575px) {
  .user-form .ant-form-item-label[class*='ant-col-'] {
    margin-bottom: 0px;
  }

  .user_form_fields .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-24 {
    padding: 0 !important;
  }

  .user_form_fields .ant-upload.ant-upload-select {
    display: inline-block;
    width: 100%;
  }

  .otherMail-input-field input {
    margin-left: 0px !important;
    padding: 0 10px !important;
  }
}
