.drag-visible .question {
  margin-bottom: 0 !important;
}

.drag-visible .star {
  color: red;
}

.ant-collapse-header .ques-div .ques-data-div {
  padding: 6px !important;
  display: inline-flex !important;
  flex-wrap: nowrap;
  height: fit-content !important;
  justify-content: left !important;
  margin-top: 1.2vh;
  position: relative;
  justify-content: center;
  bottom: 20px;
  right: 15px;
}
.action-icons.ques-actions-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  position: relative;
  bottom: 50px;
  right: 10px;
}
.ques-data-div {
  padding: 10px !important;
}

@media screen and (max-width: 749px) {
  .ant-collapse-item.site-collapse-custom-panel .ques-container-div {
    height: auto !important;
  }
  .ant-collapse-header .ques-div .ques-data-div {
    padding: 1px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column;
    margin-top: 0px !important;
    height: fit-content !important;
    padding: 5px;
    justify-content: left !important;
    position: relative;
    bottom: 20px;
    right: 15px;
  }
  .ant-collapse-content.ant-collapse-content-active {
    width: 100%;
  }
  .ant-collapse-header.ques-div {
    position: relative;
  }
  .site-collapse-custom-panel .ques-container-div .ques-div {
    height: 19px;
  }
  .ant-collapse-extra {
    width: 42%;
    margin-right: 0px;
  }
  .action-icons.ques-actions-container {
    bottom: 48px;
    right: 1px;
  }
}
