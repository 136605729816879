.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.menu-wrapper {
  overflow: auto;
  height: calc(100vh - 100px);
  overflow-x: hidden;
}

.menu-wrapper::-webkit-scrollbar {
  /* display: none; */
  /* width: 2px; */
  scrollbar-width: thin;
  width: 10px;
}

.menu-wrapper::-webkit-scrollbar-track {
  background: #9f8c8b;
}

/* Handle */
.menu-wrapper::-webkit-scrollbar-thumb {
  background: #c43530;
}

/* Handle on hover */
.menu-wrapper::-webkit-scrollbar-thumb:hover {
  background: #9f0e01;
}

.modal_note_text {
  margin-top: 16px;
  color: #009902;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
}
.version-print {
  opacity: 0.4;
  margin-bottom: 0;
  text-align: center;
}

.logo {
  cursor: pointer;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12px 0;
}

.logo a {
  text-align: center;
}

.logo img {
  width: 65%;
}

.profile-menu {
  display: flex;
  border: 1px solid #cccccc;
  padding: 8px 0;
  border-radius: 5px;
}

.gx-avatar-role {
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
}

.ant-menu-submenu-popup .ant-menu.ant-menu-sub.ant-menu-vertical {
  background-color: #fff !important;
}

.custom-sub-menu div {
  display: flex;
  color: #fff;
  align-items: center;
}

.menu-divider-space {
  padding: 0 20px;
}

.menu-divider {
  border-right: 1px dotted silver;
}

.menu-divider.notification {
  text-align: center;
  font-size: 20px;
}

.menu-divider.notification i {
  font-size: 20px;
}

.header .user a {
  color: inherit;
}

.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list {
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  margin-top: 0px;
}

.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i {
  font-size: 17px;
}

.anticon.custom-discussions {
  transform: translate3D(0, -1px, 0) scale(1.33);
}

.anticon.custom-discussions svg {
  height: 14px;
}

.ant-menu-item-selected .anticon.custom-discussions,
.ant-menu-item:hover .anticon.custom-discussions {
  fill: #1890ff;
}

.ant-layout-sider .ant-menu .ant-menu-item .icon.menu-profile {
  font-size: 18px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout .ant-layout-content {
  height: calc(100vh - 64px);
  /* background: #fff; */
  /* margin: 24px 16px; */
  padding: 24px;
  overflow-y: scroll;
}

.layout-drawer .ant-drawer-content {
  /* background-color: #003366 !important; */
  background-color: #03080e !important;
}

.layout-drawer .ant-drawer-header {
  /* background-color: #003366 !important; */
  background-color: #03080e !important;
  padding: 0 !important;
  border: 0 !important;
  text-align: center;
}

.layout-drawer .ant-drawer-title {
  margin: 10px 0;
}

.layout-drawer .ant-drawer-body {
  /* background-color: #003366; */
  background-color: #03080e;
  color: #038fdd;
}

.gx-avatar-name .header-name-ml {
  margin-left: 0.5rem;
}

/*
.content-wrapper {
  max-height: calc(100vh - 74px - 50px - 50px);
} */

.admin-content-wrapper {
  max-height: none;
  overflow: scroll;
}

@media screen and (max-width: 575px) {
  .gx-avatar-name .header-name {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 719px) {
  .site-layout .ant-layout-content {
    background: #fff;
    max-height: calc(100vh - 120px);
    padding: 10px;
  }

  #ILTS-REPORT-PDF .ant-table-wrapper {
    width: 100%;
  }
}

/* START - notification */
.notificationBar {
  text-align: center;
  width: 100%;
  overflow: hidden;
  margin: auto;
  background-color: #b64d4a;
  text-transform: uppercase;
  padding: 15px;
}

.notificationMsg {
  color: #ffffff;
  font-family: Roboto, Open Sans, Helvetica Neue, sans-serif;
}

p.version-print {
  color: #fff;
}

.notificationMsg a {
  font-weight: bold;
  /* color: #fa8c15; */
  /* color: #003365; */
  color: white;
  text-decoration: underline;
}

/* END - notification */

.verification-sidebar .logo,
.verification-sidebar li.ant-menu-item,
.verification-sidebar li.ant-menu-submenu {
  pointer-events: none;
  opacity: 0.5;
}

.verification-sidebar .logo,
.verification-sidebar ul {
  cursor: not-allowed;
}

/* new sider css */

.layout {
  position: relative !important;
  min-height: 100vh !important;
}

.sider {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff !important;
  z-index: 1000;
  padding: 31px 28px;
  transition: transform 0.3s ease !important;
  border-right: 1px solid #e1e3ea !important;
}

.sider-collapsed {
  left: 90px;
  transform: translateX(-100%);
  width: 24px;
}

.sider-expanded {
  transform: translateX(0);
  width: 100%;
}

.header {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  height: 51px;
}

.header-collapsed {
  width: 30px;
}

.logo {
  width: 173px;
  height: 51px;
}

.burger-menu-button {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  height: 100%;
  padding: 0 !important;
  border: none !important;
  width: 30px;
}

.burger-menu-icon {
  width: 100%;
}

.menu-wrapper {
  margin-top: 30px;
}

.content {
  background: #fff !important;
  border: 2px solid #f1f1f2 !important;
  height: calc(100vh - 235px);
  overflow: auto;
  transition: margin-left 0.3s ease !important;
}

.content-wrapper {
  background: #fff;
  min-height: 280px;
  height: 100vh;
}

/* Header Css */

/* HeaderNew.css */
.header-new {
  padding: 25px 30px;
  height: 98px;
  background: #fff;
  z-index: 1;
  position: fixed;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  box-shadow: none;
}

.header-new-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.header-new-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #858585;
  margin-top: 16px;
}

.header-new-text-with-tag {
  display: flex;
  gap: 16px;
}

.header-new-text-with-tag-description {
  display: block;
}

.header-new-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-new-user-avatar-container {
  height: 48px;
  display: flex;
  gap: 8px;
}

.header-new-user-avatar {
  height: 48px;
  width: 48px;
  border: 2px solid #af0000;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #af0000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #af00001a;
}

.header-new-user-info {
  padding: 4px 0;
  display: block;
  align-items: center;
}

.header-new-user-info-mobile {
  display: none;
}

.header-new-user-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}

.header-new-user-code {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  align-self: center;
  display: flex;
  color: #858585;
  margin-top: 8px;
}

.header-new-logout-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-new-logout-text {
  padding: 6px 0 6px 8px;
}

.user-score-status {
  display: flex;
  align-items: center;
}

.user-score-status-mobile {
  display: none;
}

.red-tag {
  width: 100%;
  background: #fff !important;
  border: 1px solid #af00004d !important;
  margin-bottom: 0px !important;
}

.green-tag {
  width: 100%;
  background: #fff !important;
  border: 1px solid #13806c4d !important;
  margin-bottom: 0px !important;
}

.risk-score-div {
  padding: 16px;
}

.risk-score-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #af0000;
}

.risk-score-text-green {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #13806c;
}

.risk-score-total {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #af0000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 6px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.risk-score-total-green {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #13806c;
  color: #fff;
  padding: 2px 8px;
  border-radius: 6px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

/* footer css */

/* Footer.css */

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  align-items: center;
  background: #fff;
}

.footer-content {
  display: flex;
  gap: 20px;
  align-items: center;
}

.phone-info {
  display: inline-table;
  align-items: flex-end;
  height: 22px;
}

.phone-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000;
  margin-left: 8px;
  display: flex;
  align-items: flex-end;
}

.create-ticket-button {
  margin: 0 !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #af0000 !important;
  background: #af00000d !important;
  border: 1px solid #af000080 !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.load_ai_button {
  cursor: pointer;
  margin: 0 !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #af0000 !important;
  background: #ffffff !important;
  border: 1px solid #af000080 !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.resubmit-button {
  display: inline-flex !important;
  border-radius: 8px !important;
  height: 56px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  align-items: center;
  padding: 16px 20px;
  height: 100%;
  margin-bottom: 0px !important;
  background-color: #fff !important;
  color: #000 !important;
  align-items: center;
  margin-right: 15px !important;
}

.show-plan-button {
  display: inline-flex !important;
  border-radius: 8px !important;
  height: 56px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  align-items: center;
  padding: 16px 20px !important;
  height: 100%;
  margin-bottom: 0px !important;
  background-color: #fff !important;
  color: #000 !important;
  align-items: center;
  margin-right: 15px !important;
}

.hide-resubmit-btn {
  display: none !important;
}

.yes-next-button {
  display: flex;
  border-radius: 8px !important;
  height: 56px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  align-items: center;
  padding: 16px 20px;
  height: 100%;
  margin-bottom: 0px !important;
  background-color: #af0000 !important;
  color: #fff !important;
}

.confirm-next-button {
  display: flex;
  border-radius: 8px !important;
  height: 56px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  align-items: center;
  padding: 16px 20px;
  height: 100%;
  margin-bottom: 0px !important;
  background-color: #13806c !important;
  color: #fff !important;
  margin-right: 8px !important;
}

.ant-btn:hover {
  border-color: #d9d9d9 !important;
}

.sidebar_tab_name {
  display: inline;
}

.notification_icon {
  width: 25px;
}

.notification_counter {
  padding: 5px;
  background: #db2534;
  border-radius: 50%;
  position: relative;
  top: -8px;
  right: 12px;
  height: 22px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: 'Public Sans';
}

.notification_display_div {
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #000000;
  padding: 15px;
  border-radius: 8px;
  background: whitesmoke;
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.no_notification {
  height: 60px;
  width: 85%;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_display_div_read {
  background: #af00001a;
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #af000080;
  color: #af0000;
  padding: 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.read_all_notification_btn {
  margin-bottom: 0px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  border: 1px solid #af000080 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #af0000 !important;
  background: #af10000d !important;
}

.notification-popover {
  top: 50px !important;
}

.notification-popover .ant-popover-inner-content {
  width: 400px !important;
  padding: 0px !important;
}

.notification-list {
  padding: 10px;
}

.notification-card {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #000000;
}

.notification-card-read {
  opacity: 0.75;
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #525050;
}

.notification-content {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 40px;
  height: 40px;
}

.notification-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.notification-title-read {
  margin: 0;
  font-size: 15px;
  color: #333;
}

.notification-footer {
  text-align: right;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.notification_action_btns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 85%;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .gx-avatar-role {
    display: none;
  }

  .admin-content-wrapper {
    max-height: none;
    overflow: auto;
  }

  .sider {
    padding: 14px 10px !important;
  }

  .header-new {
    padding: 25px 30px;
    background: #fff;
    width: 100% !important;
  }

  .header-new-user-info {
    display: none;
  }

  .header-new-user-info-mobile {
    display: block;
  }

  .header-new-user-avatar {
    height: 30px !important;
    width: 30px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-top: 0px;
  }

  .header-new-user-avatar-container {
    display: flex;
    align-items: center;
    height: auto;
  }

  .header-new-user-code {
    font-size: 14px;
  }

  .header-new-user-name {
    font-size: 16px;
    line-height: 20px;
  }

  .header-new-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .resubmit-button {
    padding: 5px 20px !important;
    height: 38px !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .show-plan-button {
    height: 38px !important;
    padding: 8px !important;
    font-size: 12px !important;
  }

  .yes-next-button {
    height: 38px !important;
    font-size: 12px !important;
  }

  .confirm-next-button {
    height: 36px !important;
  }

  .risk-score-text {
    font-size: 12px;
  }

  .risk-score-total {
    padding: 0px 4px;
    margin-left: 8px;
    font-size: 10px;
  }

  .risk-score-div {
    padding: 2px !important;
  }

  .user-score-status {
    display: none;
  }

  .user-score-status-mobile {
    display: flex;
    align-items: center;
  }

  .menu-wrapper {
    height: calc(100vh - 150px);
    margin-top: 20px;
  }

  .content-wrapper {
    height: calc(100vh - 20px);
    overflow: auto;
  }

  .notification-popover .ant-popover-inner-content {
    width: 100% !important;
  }

  .notification-card,
  .notification-card-read,
  .notification_action_btns,
  .no_notification {
    width: 100% !important;
  }
  /* .sidebar_tab_name {
    display: none;
  } */
}
