.agreement-preview {
  width: 100% !important;
}

.pdf-viewer-button {
  display: flex;
  justify-content: space-around;
}

.pdf-viewer-page-text {
  align-self: center;
}

.buttons-viewer {
  font-size: 30px;
}

.agreement_main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.agreement_text {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #181c32;
}

.agreement_btn_div {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.agreement_submit_btn {
  width: 7% !important;
  border-radius: 8px !important;
  padding: 16px !important;
  height: 42px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media (max-width: 749px) {
  .agreement_submit_btn {
    width: 35% !important;
    padding: 6px !important;
  }

  .agreement_text {
    font-size: 18px;
  }
  .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16 {
    padding: 0px;
  }
}
