.form-field {
  margin-top: 5%;
  background: #fff;
  border-radius: 8px;
  padding: 25px;
}

.form-element {
  width: 100%;
}

.re_evaluate_main_div {
  padding: 32px 40px;
}

.re_evaluate_text {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #181c32;
  margin-bottom: 16px;
}

.re_evaluate_form {
  border: 1px solid #f1f1f2;
  box-shadow: 0px 0px 15px 0px #0000000d;
  padding: 24px;
  border-radius: 14px;
  margin-bottom: 16px;
}

.re_evaluate_form_field_div {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.submit_btn {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: rgb(255, 255, 255) !important;
  background: rgb(175, 0, 0) !important;
  border: 1px solid rgb(175, 0, 0) !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
}

.re_evaluate_divider {
  border: 1px solid #e1e3ea;
}

.otp_form_div {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  margin-top: 16px;
}

@media (max-width: 749px) {

  .otp_form_div,
  .re_evaluate_form_field_div {
    display: grid;
  }

  .re_evaluate_main_div {
    padding: 10px;
  }

  .re_evaluate_form {
    padding: 20px;
  }

  .re_evaluate_text {
    font-size: 18px;
  }
}
