.book_advisory_modal .ant-modal-close {
  display: none;
}

.on_time_book_advisory_modal {
  width: 416px !important;
}

.on_time_book_advisory_modal .ant-modal-close {
  display: none;
}

.book_advisory_div {
  border: 1px solid #000;
  border-radius: 5px;
  height: 200px;
  color: #000;
}

.book_advisory_sub_div {
  margin: 10px;
}

.book_advisory_btn {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 32px;
}

button.ant-btn.advisory_cancel_btn {
  border: 1px solid #919eab52;
  margin-bottom: 0px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0d0e14;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

button.ant-btn.advisory_verify_btn.ant-btn-primary {
  margin-bottom: 0px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 4px 8px 0px #af00003d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.edit_advisory_date_div {
  display: grid;
  justify-content: center;
}

.edit_date {
  display: flex;
  justify-content: center;
}

.edit_advisory_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-top: 16px;
  display: grid;
  width: fit-content;
}

.calendar_text {
  display: flex;
  justify-content: center;
}

.edit_advisory_heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.5px;
  text-align: left;
  color: #000;
}

.book_advisory_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.5px;
  text-align: left;
  color: #000;
}

.book_advisory_text_description {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #af0000;
  margin-top: 8px;
}

.book_advisory_text_sub_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.45px;
  text-align: left;
  color: #000;
  margin-top: 16px;
}

.book_advisory_slot_heading {
  color: #000;
  font-size: 20px;
}

.book_advisory_slot_sub_heading {
  font-size: 17px;
}

.book_advisory_slot_sub_heading_value {
  font-size: 17px;
  font-weight: 400;
}

.book_advisory_slot_main_div {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #000;
  border-radius: 10px;
}

.book_advisory_slot_edit_and_delete {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.booking_advisory_slot_row {
  border-bottom: 1px solid #000;
}

.booking_advisory_slot_row_1 {
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.booking_slot_client_code_div {
  border-right: 1px solid #000;
  width: 50%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  color: #000;
}

.booking_slot_client_name {
  width: 100%;
  border-right: 1px solid #000;
  display: flex;
  padding-left: 15px;
  align-items: center;
  color: #000;
}

.booking_slot_advisory_date_div {
  width: 50%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  color: #000;
}

.booking_slot_advisory_edit_div {
  width: 15%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.booking_slot_advisory_delete_div {
  width: 18%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #000;
}

.booking_advisory_slot_sub_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  height: 300px;
  align-items: flex-end;
}

.booking_advisory_slot_sub_heading_div {
  width: 100%;
}

.booking_slot_note_text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
}

.booking_slot_thanks_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #af0000;
}

.font_icon {
  color: #fff !important;
  font-weight: bold;
}

.modal_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.5px;
  text-align: center;
  color: #000;
  margin-bottom: 32px;
}

.modal_text_heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.5px;
  text-align: center;
  color: #000;
}

.modal_note_text {
  margin-top: 16px;
  color: #009902;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
}

.modal_note_text_description {
  margin-top: 16px;
  color: #009902;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.advisory_table table {
  box-shadow: 1px 2px 4px 0px #272d330a;
  border: 1px solid #919eab52;
  border-radius: 8px !important;
}

@media (min-width: 320px) and (max-width: 719px) {
  .book_advisory_slot_sub_heading,
  .book_advisory_slot_sub_heading_value {
    font-size: 12px;
  }

  .booking_slot_client_name {
    width: 75% !important;
  }

  .booking_slot_note_text,
  .modal_note_text {
    text-align: left;
  }
}
