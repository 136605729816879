.client-form .ant-form-item-label {
  text-align: left;
}

.client-form .ant-form-item {
  margin-bottom: 0;
}

.client-form .ant-input-password .ant-input {
  padding: 4px 11px;
}

.client-form .ant-btn {
  margin-top: 10px;
}

.client-form .ant-upload .ant-btn {
  margin: 0;
}

.client-form .ant-upload-list-item {
  margin-bottom: 10px;
}

.steps-action {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

/* .ant-btn.ant-btn-primary{
  margin-bottom: 0px;
} */
@media (max-width: 575px) {
  .client-form .ant-form-item-label[class*='ant-col-'] {
    margin-bottom: 0px;
  }

  .ant-col.gutter-row.invoice_setting_form.ant-col-xs-24.ant-col-sm-24.ant-col-md-12.ant-col-lg-12.ant-col-xl-12 {
    padding: 0px !important;
  }

  .invoice-report-table.ant-spin-container {
    height: 75vh !important;
  }
}

.client-form {
  overflow-y: scroll;
  height: 72vh;
  overflow-x: hidden;
}

.ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-24 {
  padding: 0px;
}

/* .ant-col.gutter-row.ant-col-xs-24.ant-col-sm-24.ant-col-md-12.ant-col-lg-12.ant-col-xl-12 {
  padding: 0px;
} */
.client-email-input {
  text-transform: lowercase;
}

.client-log-table {
  width: 30%;
}

.column-name-type {
  text-transform: capitalize;
}

.invoice_table_amc .ant-table-content {
  overflow: auto;
}

.client-detail-date-picker .ant-picker-dropdown {
  position: absolute !important;
  z-index: 1000;
}
td.ant-table-cell.column-name-type {
  font-size: 14px;
}
@media screen and (max-width: 749px) {
  td.ant-table-cell.column-name-type {
    font-size: small;
  }
}
