.Chart-break {
  display: none;
}

/* .hidden-client-data-th {
  border-bottom: 1px solid #e8e8e8 !important;
  border-right: 1px solid #e8e8e8;
} */
/*
.report-text {
  border-bottom: 1px solid #e8e8e8 !important;
  border-right: 1px solid #e8e8e8;
} */

/* .report-numeric{
  border-bottom: 1px solid #e8e8e8 !important;
  border-right: 1px solid #e8e8e8;
} */
