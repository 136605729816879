.terms_page {
  margin-top: 16px;
}

.terms_page_title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.terms {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-top: 16px;
}

.terms_details {
  margin-top: 16px;
}

.terms_detail_box {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 16px;
}

.alphabet_text_box {
  display: grid;
  justify-content: start;
  margin-left: 30px;
}

.highlighted_text {
  color: #af0000;
  font-weight: 600;
}

.term_note {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000;
  margin-top: 16px;
}

.terms_number {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: #af0000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.terms_alphabet {
  width: 24px;
  height: 24px;
  background: #af000033;
  color: #af0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.terms_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000;
  display: flex;
  align-items: center;
  display: block;
}

.term-note {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000;
}

.list_per_value {
  margin: 0;
}

.pending_advisory_main_div {
  margin-top: 5px;
  /* border-top: 1px solid #000; */
}

.pending_advisory_sub_div {
  border: 1px solid #000;
  border-radius: 10px;
}

.pending_advisory_div_column {
  border-right: 1px solid #000;
}

.pending_advisory_div_column_new {
  border: 1px solid #000;
  border-radius: 10px;
}

.column_for_advisory_table,
.column_for_advisory_table_new {
  margin-top: 20px;
}

.question_icon {
  float: right;
}

.que_mark {
  color: #fff !important;
  font-weight: bold;
}

.pending_advisory_accept_btn {
  background: green !important;
  color: #fff !important;
  border: none !important;
}

.pending_advisory_accept_btn:hover {
  color: #fff !important;
  border: none !important;
  border: none !important;
}

.more_details_btn {
  margin-top: 20px;
  width: 90%;
  color: #000 !important;
  background: #ffff47 !important;
  border-radius: 10px !important;
}

.more_details_btn:hover {
  color: #000 !important;
  border: none !important;
}

.pending_advisory_modal_sub_title {
  text-align: center;
  color: #000;
  font-weight: 500;
  font-size: 18px;
}

.text_div {
  color: #000;
  width: 40%;
  align-self: center;
}

.text_div_title {
  padding-left: 10px;
}

.model_static_text {
  padding-left: 5px;
  color: #000;
}

.table_data_div {
  border: 1px solid #000;
  border-radius: 10px;
}

.table_data_col_1 {
  color: #000;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-bottom: 1px solid #000;
}

.table_data_col_2 {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  text-align: center;
}

.table_data_col_2_row_1 {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 30px !important;
  border-bottom: 1px solid #000;
  color: #000;
}

.table_data_col_2_row_2 {
  color: #000;
  border-bottom: 1px solid #000;
}

.table_data_col_row_2_col_1 {
  border-right: 1px solid #000;
  height: 30px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.table_data_col_row_2_col_2 {
  height: 30px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.table_data_col_3 {
  text-align: center;
}

.table_data_col_3_row_1 {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 30px !important;
  border-bottom: 1px solid #000;
  color: #000;
  width: 106%;
}

.data_col_1 {
  height: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #000;
  border-bottom: 1px solid #000;
}

.data_col_1_new {
  height: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #000;
}

.data_col_2 {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  width: 105% !important;
}

.data_col_3_row {
  border-bottom: 1px solid #000;
}

.data_col_2_row {
  border-bottom: 1px solid #000;
}

.data_col_2_row_new {
  display: none;
}

.data_col_3_row_col_1 {
  height: 30px;
  border-right: 1px solid #000;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  color: #000;
}

.data_col_3_row {
  width: 106%;
  border-bottom: 1px solid #000;
}

.data_col_3_row_new {
  display: none;
  width: 106%;
}

.data_col_3_row_col_2 {
  height: 30px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  color: #000;
}

.table_data_col_3_row {
  color: #000;
  border-bottom: 1px solid #000;
  width: 106%;
}

.date_div {
  border: 1px solid #000;
  border-radius: 5px;
  height: 50px;
  margin-top: 5px;
  width: 33%;
}

.update_trade_date {
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;
}

.advisory_collapse .ant-collapse-header {
  text-align: justify !important;
  color: #000 !important;
}

.advisory_collapse .ant-collapse-item {
  margin: 5px 0px !important;
  border: none !important;
}

.advisory_collapse {
  border: none !important;
}

.update_trade_table {
  margin: 35px 10px 10px 10px;
  border: 1px solid #000;
  border-radius: 10px;
}

.update_trade_table_row {
  border-bottom: 1px solid #000;
  margin: 0px !important;
  height: 50px !important;
  color: #000;
}

.update_trade_table_row_new {
  margin: 0px !important;
  height: 50px !important;
  color: #000;
}

.update_trade_table_col {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.update_trade_table_script {
  display: flex !important;
  align-items: center !important;
  padding-left: 50px !important;
}

.update_trade_table_script_total {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.update_trade_table_col_border {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.scenario_advisory {
  margin: 10px;
}

.scenario_advisory_text_div {
  border: 1px solid #000;
  border-radius: 5px;
}

.scenario_advisory_text_text {
  text-align: justify;
  margin: 10px;
  color: #000;
  font-weight: 500;
}

.update_trade_table_col_script {
  border-right: 1px solid #000;
}

.pending_advisory_row_col_2 {
  color: #000;
}

.new_advisory_text {
  text-align: center;
  margin-top: 10px;
}

.pending_advisory_row_col_2_text {
  text-align: left;
  font-weight: 500;
  color: #000;
}

.pending_advisory_row_col_2_para {
  text-align: left;
  color: #000;
}

.green_btn {
  background-color: green !important;
  color: #fff !important;
  border: none !important;
  border-radius: 12px !important;
  width: 200px !important;
}

.chart_legend {
  display: flex;
  width: 100% !important;
  margin-top: -20px;
}

.legend1 {
  display: flex;
  align-items: center;
}

.legend_dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.chart_legend2 {
  display: flex;
  justify-content: space-evenly;
}

.more_details_legend {
  height: 10px;
  width: 10px;
}

.chart_description {
  text-align: center;
  font-weight: 500;
  color: #000;
  font-size: 16px;
  margin-top: 10px;
}

.date_picker {
  background: none !important;
  border: none !important;
}

.date_picker .ant-picker-focused {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.date_picker .ant-picker-input input {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.date_picker .ant-picker-input .ant-picker-suffix {
  color: #000 !important;
}

.advisory_update_table .text_align,
.advisory_table .text_align {
  text-align: end !important;
}

.advisory_update_table .text_left {
  text-align: start !important;
}

.advisory_update_table .ant-table-body {
  height: 70% !important;
}

.advisory_update_table .ant-table-thead > tr > th {
  padding: 5px !important;
}

.pending_advisory_model {
  width: 50% !important;
}
.ant-modal.pending_advisory_model {
  top: 30px !important;
}

.confirm_main_div {
  border: 1px solid #000;
}

.confirm_img {
  text-align: center;
  margin-top: 20px;
}

.sign_text {
  text-align: center;
  margin-top: 10px;
}

.sign_heading {
  color: #3b9b31;
  font-size: 20px;
}

.confirm_text_div {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}

.confirm_text_div_new {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  margin-top: 24px;
}

.confirm_text_second_div {
  width: 92%;
  margin-left: 21px;
}

.confirm_btn_div {
  text-align: center;
  margin-top: 20px;
}

.scenario_analysis_div {
  padding: 0;
}

.more_details_chart_2 {
  border: 1px solid #000;
}

.advisory_error {
  color: red;
  display: block;
}

.error_info_icon {
  display: none !important;
}

.legend_name,
.legend_name_1 {
  margin-left: 10px;
}

.advisory_input {
  display: flex;
}

.action_btn {
  text-align: center !important;
}

.back_icon_for_advisory {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 16%;
}

.pending_Advisory_Scenario_Analysis {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 320px) and (max-width: 719px) {
  .pending_Advisory_Scenario_Analysis {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .pending_advisory_div_column {
    border: none;
    padding: 0 !important;
  }

  .update_trade_table_script,
  .update_trade_table_script_total {
    padding-left: 10px !important;
    font-size: 12px;
    text-align: left;
  }

  .update_trade_table_col {
    font-size: 12px;
  }

  .scenario_analysis_div_1 {
    padding: 0 !important;
  }

  .scenario_analysis_div {
    width: 100% !important;
    border-top: 1px solid #000;
    padding-left: 10px !important;
  }

  .update_trade_table_row_new,
  .update_trade_table_row,
  .update_trade_table_script,
  .update_trade_table_script_total {
    height: 65px !important;
  }

  .more_details_chart_2 {
    margin-top: 25px !important;
  }

  .table_data_col_2_row_1,
  .table_data_col_3_row_1,
  .table_data_col_row_2_col_1,
  .table_data_col_row_2_col_2,
  .data_col_1_new,
  .data_col_1,
  .table_data_col_1,
  .data_col_3_row_col_1,
  .data_col_3_row_col_2 {
    font-size: 10px;
  }

  .table_data_col_3_row_1,
  .table_data_col_3_row,
  .data_col_3_row,
  .data_col_3_row_new {
    width: initial;
  }

  .update_trade_date .date_div {
    width: 99%;
  }

  .update_trade_table_col .ant-input-number-handler-wrap {
    display: none;
  }

  .update_trade_table_col .ant-input-number-input {
    padding: 0;
  }

  .update_trade_table_col .ant-input-number {
    margin-right: 0;
    width: 50px;
  }

  .ant-col .ant-col-4 .update_trade_table_col .update_trade_table_col_border {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .advisory_error {
    width: 100%;
    font-size: 7px;
    display: none;
  }

  .error_info_icon {
    color: red !important;
    display: block !important;
  }

  .terms_page_title {
    font-size: 16px;
    padding-left: 8px;
    text-align: left;
  }

  .legend_dot {
    height: 7px;
    width: 7px;
  }

  .legend_name {
    font-size: 11px;
    margin-left: 5px;
  }

  .legend_name_1 {
    font-size: 10px;
    margin-left: 5px;
  }

  .pending_advisory_model {
    width: 100% !important;
  }

  .advisory_update_table .ant-table-bordered .ant-table-container,
  .main_div_title {
    font-size: 10px !important;
  }

  .advisory_update_table .text_align {
    text-align: center !important;
  }

  .advisory_update_table
    .ant-table-bordered
    .ant-table-container
    .ant-table-header
    .text_align {
    padding: 0;
  }

  .update_trade_table_col_new {
    display: block;
  }

  .advisory_value_input {
    padding-left: 23px !important;
  }

  .advisory_input {
    display: grid !important;
  }

  .column_for_advisory_table_new {
    margin-right: 8px !important;
  }

  .term_note {
    margin-bottom: 16px;
  }

  .date_picker_new {
    width: 100% !important;
  }
}

@media (max-width: 320px) {
  .terms_page_title {
    font-size: 14px;
    padding-left: 8px;
    text-align: left;
  }

  .pending_advisory_div_column {
    border: none;
    padding: 0 !important;
    font-size: 11px !important;
  }

  .pending_advisory_sub_div {
    border: none !important;
    padding: 0 !important;
  }

  .update_trade_table_script,
  .update_trade_table_script_total {
    padding-left: 5px !important;
    text-align: left;
  }

  .legend_dot {
    height: 5px;
    width: 5px;
  }

  .legend_name,
  .legend_name_1 {
    font-size: 9px;
    margin-left: 5px;
  }

  .text_div {
    font-size: 11px;
    width: 44%;
  }

  .model_static_text {
    font-size: 12px !important;
    margin-top: 8px !important;
  }

  .table_data_div {
    font-size: 8px !important;
  }

  .update_trade_date .date_div {
    width: 100%;
  }

  .update_trade_table {
    font-size: 11px;
    margin: 40px -10px 0px -10px;
  }

  .update_trade_table_row_new,
  .update_trade_table_row,
  .update_trade_table_script,
  .update_trade_table_script_total {
    height: 90px !important;
  }

  .advisory_input {
    display: grid !important;
  }

  .update_trade_table_col .ant-input-number-handler-wrap {
    display: none;
  }

  .update_trade_table_col .ant-input-number-input {
    padding: 0;
  }

  .advisory_input .ant-input-number {
    width: 100%;
  }

  .advisory_error {
    width: 100%;
    font-size: 7px;
    display: none;
  }

  .error_info_icon {
    color: red !important;
    display: block !important;
  }

  .table_data_col_3,
  .table_data_col_row_2_col_1,
  .table_data_col_row_2_col_2,
  .data_col_3_row_col_1,
  .data_col_3_row_col_2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .table_data_col_3_row_1 {
    margin-right: 0 !important;
    margin-left: -1px !important;
  }

  .table_data_col_3_row {
    margin-right: 0 !important;
    margin-left: -3px !important;
  }

  .data_col_3_row,
  .data_col_3_row_new {
    width: auto !important;
  }

  .more_details_chart_2 {
    margin-top: 25px !important;
  }

  .pending_advisory_div_column_new {
    border: none !important;
  }

  .advisory_update_table .ant-table-bordered .ant-table-container,
  .main_div_title {
    font-size: 10px !important;
  }

  .advisory_update_table .text_align {
    text-align: center !important;
  }

  .advisory_update_table
    .ant-table-bordered
    .ant-table-container
    .ant-table-header
    .text_align {
    padding: 0;
  }
}

.trades_tab .ant-tabs-nav {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #858585 !important;
}

.trades_tab .ant-tabs-nav .ant-tabs-tab-active {
  color: #000 !important;
}

.custom-tooltip-update .ant-tooltip-inner {
  background: #fff !important;
  color: #af0000 !important;
}

/* .custom-tooltip-update .ant-tooltip-arrow {
  border-top-color: #fff;
} */

.custom-tooltip-update .ant-tooltip-arrow::before {
  background-color: #fff !important;
}

.date_picker_new {
  border-radius: 8px !important;
  width: 165px;
  height: 40px !important;
}

.date_picker_new .ant-picker-input,
.date_picker_new .ant-picker-input input {
  color: #858585 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
