.steps-content {
  /* min-height: 200px; */
  /* margin-top: 15px; */
  /* padding: 10px; */
  /* padding-top: 40px; */
  /* text-align: center; */
  /* background-color: #fafafa; */
  /* border: 1px dashed #e9e9e9; */
  /* border-radius: 2px; */
  /* height: calc(100vh - 102px); */
  /* overflow: auto; */
}

@media screen and (max-width: 700px) {
  .steps-content {
    /* height: 120vh; */
    padding: 0;
  }
}

.gx-app-login-content.verification {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(
    100vh - 72px - 48px - 32px - 32px - 30px - 51px - 50px - 72px
  );
}

.steps-action {
  margin-top: 24px;
}

.text-verification {
  display: flex;
  font-size: 18px;
  font-weight: normal;
  /* padding: 0 0 0 20px; */
}

.ant-input:placeholder-shown.verification-input-field {
  display: flex;
  max-width: 50%;
  max-width: 365px;
}

.verification-step3-input-field {
  padding: 0 0 0 20px;
}

.radio-verification.ant-radio-group {
  padding: 0 0 0 20px;
}

.checkbox-verification.ant-checkbox-group {
  padding: 0 0 0 0px;
}

.verification-from {
  overflow-y: scroll !important;
  height: 240px !important;
}

.annual {
  justify-content: center;
}

.agree-wrapper {
  display: flex;
}

.agree-content {
  font-size: 14px;
  text-align: start;
  margin: 0px 0px 0px 10px;
}

.verification .ant-form label {
  font-size: 16px !important;
}

.collapse .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  display: flex;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
}

.collapse .ant-radio-group {
  padding: 0 0 0 20px;
}

.collapse .ant-form-item-explain {
  display: flex;
  padding: 0 0 0 20px;
}

.collapse .ant-checkbox-group {
  display: flex;
  padding: 0 0 0 20px;
}

.require-star {
  color: red;
}

.text-verification .ant-form-item-control-input-content {
  display: flex;
  padding: 0 0 0 20px;
  max-width: 365px;
}

.risk-profile-form {
  text-align: justify;
  font-size: 14px;
}

.checkedEmail {
  justify-content: right;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

/* .collapse-question{
  max-height:calc(100vh - 72px - 32px - 90px);
  overflow: scroll;
} */
.ant-input-number.verification-input-field {
  width: 100%;
}

.ant-input-number-input {
  text-align: left !important;
}

.ant-picker-input > input {
  text-align: left;
}

/* .ant-progress-inner {
  background-color: darkgray !important;
} */

.progress {
  margin-right: 4rem;
}

.form.ant-form.ant-form-horizontal.risk-profile-form {
  text-align: center !important;
}

.gx-app-login-wrap.title-verification {
  height: auto;
}

.annual-radio-select {
  line-height: 3 !important;
}

.client-concent-checkbox-table {
  display: inline-table;
  margin: 20px 0;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  margin-bottom: 10px;
}

.content-wrapper
  .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
  display: contents;
}

.modalClose {
  display: none;
}

.display-field {
  width: 100%;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

@media (min-width: 320px) and (max-width: 719px) {
  .display-field {
    display: block !important;
  }
  .content-wrapper {
    scrollbar-width: none;
  }
}

.summary {
  border: 1px solid black;
  border-radius: 10px;
}

.summary_heading {
  color: green;
  font-size: 20px;
}

.summary_client_name {
  font-size: 16px;
  text-transform: uppercase;
}

.summary_data {
  font-size: 16px;
}

.summary_risk_profile {
  display: flex;
}

.summary_suitability {
  margin-left: 7px;
  height: 100%;
  background-color: #c4e9c4;
}

.summary_assessment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.summary_objective {
  text-align: center !important;
}

.summary_objective_below {
  text-align: left;
  margin-left: 10px;
}

.summary_risk_profile_content {
  display: flex;
  width: 90%;
  text-align: justify;
  margin-left: 10px;
  font-size: 16px;
}

.summary_risk_profile_heading {
  font-weight: bold;
  font-size: 18px;
}

.upi-payment-wrapper .ant-row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.payment_warning {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #af0000;
}

.table_edit_table_text_box .ant-row.ant-form-item {
  display: grid;
  margin: 0 0 0 -5px;
}

.table_edit_table_text_box
  .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16 {
  display: contents;
  width: 100%;
}

.faqs-modals .ant-modal-header {
  border-bottom: none !important;
}
.advisory_update_table .ant-table-tbody > tr > td {
  padding: 5px !important;
}

.faqs-modals .ant-modal-footer {
  display: none;
}

.faqs-modals {
  top: 50px !important;
}

.faqs-icon {
  padding: 10px;
  background: #af0000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-self: center;
}

.faqs-icon-div {
  display: flex;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0px !important;
  border-radius: 8px;
}

button.ant-btn.faq-btn {
  margin-bottom: 0px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  border: 1px solid #919eab52;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000;
}

/* .faqs-questions-div {
  padding: 10px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  margin-top: 10px;
} */

.faq-plus-icon {
  font-size: 15px;
  font-weight: bold;
  color: #af0000 !important;
}

.congratulations_page {
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border-radius: 12px;
  padding: 16px;
}

.header_logo_image {
  text-align: right;
}

.congratulations_sub_div {
  margin-top: 16px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.ant-picker.client-detail-date-picker {
  border-radius: 8px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 16px;
}

.ant-picker.client-detail-date-picker .ant-picker-input .ant-picker-suffix {
  margin-top: -4px;
}

.hand-shake-image {
  display: flex;
  justify-content: center;
}

.congratulations_date_select {
  font-size: 16px;
  margin-top: 30px;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: center;
}

.edit_advisory_date_div {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.congratulations_error_div {
  color: red;
  margin-top: 20px;
  text-align: center;
}

.congratulations_next_btn_div {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.congratulations_next_btn {
  padding: 0 20px;
  margin-top: 20px;
}

.faqs_page {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.faqs_title {
  font-size: 18px;
  font-weight: 700;
  align-self: end;
}

.faqs_div {
  display: flex;
  border-bottom: 1px solid #b7b7b7;
  margin-top: 20px;
}

.faq_questions_answers {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
@media screen and(max-width:749px) {
  .faq_questions_answers {
    font-size: 12px;
  }
}
.faq_answer {
  color: #858585;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}

.create_ticket_div {
  margin-top: 15px;
  text-align: justify;
}

.create_ticket_title {
  margin: 0px 0px 15px 0px;
  font-size: 15px;
  font-weight: bold;
}

.create_ticket_upload_text_div {
  margin-top: -10px;
  margin-bottom: 5px;
}

.create_ticket_upload_text {
  font-size: 14px;
  color: #af0000;
}

.ant-statistic {
  width: 25px;
  background-color: #af0000;
  border-radius: 50px;
  margin-left: 5px !important;
}

.ant-statistic-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-statistic-content-value {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.ant-statistic.modal_counter {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.counter_text {
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #af0000;
}

.modal_counter span.ant-statistic-content-value {
  padding: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.info_modal_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.5px;
  text-align: center;
  color: #000;
}

.info_modal_text_description {
  font-family: Public Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #af0000;
  margin-top: 8px;
}

.info_model_timer_text {
  font-weight: 500;
  color: #000;
  display: flex;
}

/* .pending_booking_advisory_page {
  border: 1px solid #000;
  border-radius: 5px;
} */

.pending_advisory_logo_and_title {
  display: flex;
  justify-content: space-between;
}

.pending_advisory_title_div {
  width: 54%;
  display: grid;
  justify-content: end;
}

.pending_advisory_title {
  color: #000;
  font-weight: 500;
  font-size: 21px;
}

.pending_advisory_sub_title {
  color: #000;
  font-weight: 500;
  font-size: 18px;
}

.pending_advisory_client_details_div {
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.pending_advisory_client_details_div_1 {
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  width: 100%;
}

.clients_details {
  width: 20%;
}

.client_div_for_advisory_title {
  border-bottom: 1px solid #000;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #000;
}

.client_div_for_advisory_sub_title {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #000;
}

.client_details_value {
  border-left: 1px solid #000;
  width: 100%;
}

.client_code_value {
  color: #000;
  border-bottom: 1px solid #000;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 400 !important;
}

.client_name_value {
  color: #000;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 400 !important;
}

.pending_advisory_div_2 {
  width: 50%;
  margin-left: 5px;
}

.advisory_date_and_type {
  border: 1px solid #000;
  border-radius: 5px;
}

.advisory_date_div {
  display: flex;
  height: 35px;
}

.advisory_date_div_1 {
  display: flex;
  height: 50px;
}

.advisory_date_text,
.advisory_date_text_new {
  padding-left: 10px;
  align-self: center;
  width: 40%;
  color: #000;
}

.advisory_date_value,
.advisory_date_value_new {
  width: 60%;
  border-left: 1px solid #000;
  color: #000;
  padding-left: 20px;
  padding-top: 6px;
}

.advisory_date_value_div {
  width: 60%;
  border-left: 1px solid #000;
  color: #000;
  padding-left: 20px;
  padding-top: 6px;
}

.date_value {
  width: 60%;
  border-left: 1px solid #000;
  color: #000;
  padding-left: 20px;
  padding-top: 6px;
  font-weight: 500;
}

.date_and_time_div {
  border: 1px solid #000;
  border-radius: 5px;
  height: 35px;
  margin-top: 5px;
}

.file_upload .ant-btn {
  border-radius: 15px;
  background-color: lightgray;
}

.file_upload .ant-btn:hover {
  background-color: lightgray;
  border: 0;
  color: #000;
}

.create_ticket_button {
  border-radius: 15px;
}

.review_plan {
  text-align: end !important;
}

.congratulations_main_div {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #af0000;
  text-align: center;
}

.congratulations_date_select_sub_div {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #000;
  margin-top: 16px;
}

.custom-dropdown-class
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: #af0000 !important;
  color: #fff !important;
}

.custom-dropdown-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none !important;
}

.custom-dropdown-class .ant-picker-cell .ant-picker-cell-inner {
  border: 1px solid #af0000 !important;
  color: #000 !important;
}

.custom-dropdown-class .ant-picker-cell-disabled .ant-picker-cell-inner {
  border: none !important;
  color: #00000040 !important;
}

.fin_logo_div {
  width: 200px;
  height: 70px;
}

.steps_collapse {
  display: flex;
  justify-content: space-between;
}

.steps_icon {
  text-align: end;
}

.steps_icon_span {
  color: #af0000 !important;
  font-size: 18px !important;
}

.payment_warning_btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (min-width: 320px) and (max-width: 719px) {
  .congratulations_main_div {
    font-size: 17px;
  }

  .pending_advisory_client_details_div {
    display: grid;
  }

  .pending_advisory_client_details_div_1 {
    margin-bottom: 10px;
    margin-left: 6px;
    /* width: 110%; */
  }

  .clients_details {
    width: 65%;
  }

  .new_column_for_type {
    padding: 0 !important;
    margin-left: 14px !important;
  }

  /* .pending_advisory_div_2 {
    width: 110%;
  } */

  .client_div_for_advisory_title {
    width: 100%;
  }

  .pending_advisory_title_div {
    width: 100%;
  }

  .client_code_value,
  .client_name_value {
    display: flex;
    justify-content: center;
  }

  .advisory_date_text {
    padding-left: 0px !important;
  }

  .advisory_date_text_new {
    padding-left: 7px !important;
  }

  .date_value {
    text-align: center;
  }

  .advisory_date_value_div {
    padding-left: 18px;
  }

  .congratulations_sub_div {
    text-align: justify;
  }

  .advisory_select_date {
    margin-top: 10px !important;
  }

  .congratulations_date_select_sub_div {
    text-align: justify;
  }

  .congratulations_date_select {
    display: block;
  }

  .pending_advisory_title,
  .pending_advisory_sub_title {
    font-size: 17px;
  }

  .fin_logo_div {
    margin-top: 3px;
    width: 130px;
    height: 40px;
  }

  .advisory_date_value_new {
    text-align: center !important;
  }

  .disclaimer-col-image {
    display: none !important;
  }

  .disclaimer-description {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18.8px;
    text-align: left;
    color: #000;
    margin: 15px 0 !important;
  }

  .verify_next_button {
    justify-content: end !important;
    margin-right: 0 !important;
  }

  .client-column {
    width: 100% !important;
    padding: 0 !important;
  }

  .form-outer-div {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .col-left {
    border-right: none !important;
    padding: 10px !important;
    height: auto !important;
    width: 100%;
  }

  .col-right {
    border-right: none !important;
    padding: 10px !important;
    width: 100% !important;
    height: auto !important;
  }

  .risk-profile-form .gender-radio-group {
    display: grid !important;
    gap: 8px;
  }

  .assessment-details {
    display: grid !important;
    gap: 25px;
  }

  .detail-data {
    width: 100% !important;
  }

  .sub-data-box {
    display: grid !important;
    gap: 8px;
    margin-top: 8px;
  }

  .assessment-plan-display {
    display: grid !important;
    gap: 8px;
  }

  .chart-title-user-name {
    width: max-content !important;
    display: flex;
    justify-content: normal;
  }

  .investment_planning_wrapper {
    margin-bottom: 0rem !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    border-radius: 0px !important;
  }

  .congratulations_page {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .congratulations_next_btn_div {
    width: 100%;
    display: flex;
    justify-content: center !important;
  }

  .pending_booking_advisory_page {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

@media (max-width: 320px) {
  .congratulations_main_div {
    font-size: 12px;
  }

  .congratulations_sub_div {
    text-align: justify;
    font-size: 12px;
  }

  .congratulations_date_select {
    display: block;
  }

  .congratulations_date_select_sub_div {
    text-align: justify;
    font-size: 12px;
  }

  .advisory_select_date {
    margin-top: 10px !important;
  }

  .fin_logo_div {
    margin-top: 3px;
    width: 100px;
    height: 40px;
  }

  .congratulations_next_btn_div {
    width: 100%;
    display: flex;
    justify-content: center !important;
  }

  .pending_advisory_title,
  .pending_advisory_sub_title {
    font-size: 12px;
  }

  .clients_details {
    width: 76%;
    font-size: 12px;
  }

  .advisory_date_text {
    padding-left: 0px !important;
    width: 50%;
    font-size: 12px;
  }

  .new_pending_advisory_type {
    margin-top: 10px !important;
  }

  .client_details_value {
    font-size: 12px;
  }

  .advisory_date_value {
    font-size: 11px;
    padding-left: 0;
  }

  .new_column_for_type {
    padding: 0 !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
    font-size: 11px !important;
  }

  .date_value {
    display: flex;
    align-items: center;
    padding-left: 6px !important;
  }

  .advisory_date_value_div {
    padding-left: 6px;
  }

  .advisory_date_text_new {
    font-size: 11px !important;
  }

  .disclaimer-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: left;
    color: #000;
    margin: 15px 0;
  }

  .verify_next_button {
    justify-content: end;
  }

  .client-column {
    width: 100% !important;
    padding: 0 !important;
  }

  .form-outer-div {
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

.editCollapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  text-align: left;
  color: #000;
}

.ant-collapse-header {
  height: 50px;
}

.verify_next_button {
  display: flex;
  justify-content: center;
  margin-right: 18px;
}

/* client profile 2 new */

.row-style {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 100% !important;
}

.col-left {
  border-right: 1px solid #e1e3ea;
  padding: 20px 20px 20px 20px;
  height: calc(100vh - 159px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.col-right {
  border-right: 1px solid #e1e3ea;
  padding: 31px 15px;
  height: calc(100vh - 159px);
  overflow: auto;
}

.key-info {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #181c32;
  margin-bottom: 16px;
}

.gen-extra-container {
  display: flex;
  gap: 20px;
  position: absolute;
  left: 16px;
  user-select: none;
}

.gen-extra-edit {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.gen-extra-edit img {
  margin-right: 5px;
}

.gen-extra-edit span {
  color: #af0000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.45px;
  text-align: left;
}

.panel-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.panel-content div {
  font-size: 14px;
  line-height: 16.45px;
  text-align: left;
}

.panel-content div.left {
  color: #858585;
  font-weight: 400;
}

.panel-content div.right {
  color: #000;
  font-weight: 500;
}

.panel-content div.max-exposure-left {
  font-size: 17px;
  font-weight: 600;
  color: #000;
}

.panel-content div.max-exposure-right {
  color: #000;
  font-size: 17px;
  font-weight: 600;
}

.ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.risk-profile-collapse .ant-collapse-content {
  border-top: none !important;
}

.risk-profile-collapse
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  text-align: left;
  color: #000;
}

.risk-profile-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px 16px 16px;
}

.risk-profile-collapse .text-verification {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000;
}

.risk-profile-collapse .text-verification span {
  margin-left: 3px;
}

.risk-profile-form .ant-form-item label {
  width: 100%;
}

.risk-profile-form .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #e9ecec;
}

.risk-profile-form .gender-radio-group {
  display: flex;
  gap: 8px;
}

.risk-profile-form .gender-radio-button {
  width: 100%;
  border-radius: 6px !important;
  color: #7e8299;
  text-align: center;
  background: #f6f7f7;
  border: 1px solid #e9ecec;
  height: 38px;
}

.risk-profile-form .gender-radio-button:hover {
  color: #7e8299;
}

label.gender-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper:not(
    :first-child
  )::before {
  width: 0px !important;
  background-color: transparent;
}

div#register_gender
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  width: 100%;
  border-radius: 6px !important;
  /* border: 1px solid #af0000; */
  /* background: #af00001a; */
  /* color: #af0000; */
  text-align: center;
  height: 38px;
  font-weight: 600 !important;
}

label.gender-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  width: 100%;
  border-radius: 6px !important;
  /* border: 1px solid #af0000; */
  /* background: #af00001a; */
  /* color: #af0000; */
  text-align: center;
  box-shadow: none;
  height: 38px;
  /* border-right-color: #af0000 !important; */
  font-weight: 600 !important;
}

label.gender-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:focus {
  width: 100%;
  border-radius: 6px !important;
  /* border: 1px solid #af0000; */
  /* background: #af00001a; */
  /* color: #af0000; */
  text-align: center;
  box-shadow: none;
  outline: 0px;
  height: 38px;
  font-weight: 600 !important;
}

.video-modal .ant-modal-close {
  display: block;
}

.create-ticket-modal .ant-modal-close {
  display: block !important;
}

.ant-modal-close {
  display: block;
}

.footer-btn-div {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}

button.ant-btn.footer-cancel-btn {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  color: #0d0e14;
}

button.ant-btn.footer-submit-btn.ant-btn-primary {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
}

/* Summary */

.summary-title-box {
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 10px;
}

.summary-title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.8px;
  text-align: left;
  color: #000;
}

.summary-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #858585;
}

.sub-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.sub-box-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.45px;
  text-align: left;
  color: #000;
}

.sub-box-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.45px;
  text-align: left;
  color: #858585;
  display: flex;
  align-items: center;
  gap: 8px;
}

.assessment-details-box {
  padding: 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px;
  background: #13806c0d;
  border: 1px solid #13806c4d;
  width: 100%;
}

.assessment-details-box-1 {
  padding: 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px;
  background: #af00000d;
  border: 1px solid #af00000d;
  width: 100%;
}

.assessment-plan-display {
  display: flex;
  gap: 8px;
}

.assessment-details {
  display: flex;
  gap: 25px;
}

.detail-data {
  width: 66.66%;
}

.sub-data-box {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.chart-title-user-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.45px;
  color: #000;
  display: flex;
  justify-content: center;
}

.chart-title-user-name span {
  margin-left: 5px;
  color: #af0000;
}

.chart-block {
  width: 33.33%;
}

.assessment-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #13806c;
  margin-bottom: 8px;
}

.assessment-title-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #af0000;
  margin-bottom: 8px;
}

.assessment-description {
  background: #13806c29;
  padding: 1px 8px;
  gap: 0px;
  border-radius: 6px;
  color: #13806c;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: fit-content;
  padding: 5px 8px;
}

.assessment-description-1 {
  background: #af000029;
  padding: 1px 8px;
  gap: 0px;
  border-radius: 6px;
  color: #af0000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: fit-content;
  padding: 5px 8px;
}

/* Desclaimer */

.heading-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #000;
}

.phone-image {
  width: 75%;
}

.ant-col.ant-col-8.col-right.disclaimer-col {
  padding: 31px !important;
}

.disclaimer-col-image {
  display: block;
}

.store-logo {
  display: flex;
  justify-content: center;
}

.phone-block {
  margin: 25px 0;
  display: flex;
  justify-content: center;
}

.heading-title-for-disclaimer {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.15px;
  text-align: left;
  color: #000;
}

.disclaimer-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  text-align: left;
  color: #000;
  margin: 30px 0;
}

.disclaimer-notes {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  text-align: left;
  color: #af0000;
}

/* Kyc Doc */

a.ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1,
span.ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1 {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #858585;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.ant-row.ant-form-item.upload-document
  .ant-upload.ant-upload-select.ant-upload-select-picture {
  width: 100%;
}

button.ant-btn.upload-doc-btn {
  margin: 1px;
  padding: 16px;
  border-radius: 6px;
  height: 100%;
  background: #8585850d;
  border: 1px solid #8585854d;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #858585;
  display: flex;
  align-items: center;
}

.download-btn-block {
  position: absolute;
  bottom: 8px;
  z-index: 9999;
  left: 5%;
}

.pdf-viewer-button {
  position: absolute;
  bottom: 15px;
  z-index: 9999;
  right: 5%;
}

.pdf-viewer-page-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
}

.pdf-viewer-btns-block {
  display: flex;
  gap: 8px;
}

button.ant-btn.buttons-viewer {
  margin-bottom: 0px !important;
  background: #af0000;
  border: none;
  border-radius: 8px;
  margin-right: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
}

button.ant-btn.buttons-viewer:hover {
  background-color: #af0000;
}

button.ant-btn.download-pdf-btn {
  background: #af0000;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0px;
}

/* investment planning */

.wording-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
  text-transform: capitalize;
}

.review-plan-data-table {
  border: 1px solid var(--Color-Outlined-Dark, #90a0af3d);
  border-radius: 8px;
  box-shadow: 1px 2px 4px 0px #272d330a;
  overflow: hidden;
}

.review-plan-data-table .ant-table {
  border-radius: 8px !important;
  box-shadow: 1px 2px 4px 0px #272d330a;
}

.review-plan-data-table th.ant-table-cell.report-text,
th.ant-table-cell.report-numeric.review_plan {
  background: #f4f6f8 !important;
}

@media screen and (max-width: 749px) {
  td.ant-table-cell.report-text {
    padding: 8px 8px;
  }
  td.ant-table-cell.report-numeric.review_plan {
    padding: 8px 8px;
  }
}
/* e-mandate */

.details-box-for-mandate {
  padding: 16px;
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border-radius: 12px;
  margin-bottom: 8px;
}

.detail-box-title {
  display: flex;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
}

button.ant-btn.back-btn {
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 16px;
  height: 40px;
}

button.ant-btn.back-btn:hover {
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 16px;
  height: 40px;
  color: #000;
}

.back-text {
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  margin-left: 4px;
}

.faqs-questions-div .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border: 1px solid #0000001a;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 10px;
}

.faqs-questions-div .ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}

.td.ant-table-cell {
  padding: 2px !important;
}
.faqs-questions-div
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 16px;
  color: #858585;
}

.kyc_doc_display {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(133, 133, 133);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  display: -webkit-box;
  max-width: 20rem;
  overflow: hidden;
  margin-top: 8px;
  text-align: center;
}

/* pending advisory */

.pending_booking_advisory_page {
  padding: 16px;
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border-radius: 12px;
}

.pending_advisory_logo_and_title {
  display: flex;
  justify-content: space-between;
}

.pending_advisory_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #000;
}

.pending_advisory_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #858585;
  /* margin-top: 8px; */
}

.ant-row.client-profile-row {
  flex-direction: row !important;
}

.client-column {
  padding: 0 20px;
}

.form-outer-div {
  box-shadow: 0px 0px 15px 0px #0000000d;
  border: 1px solid #0000001a;
  border-radius: 12px;
  padding: 20px 0;
}

.editCollapse .ant-collapse-content.ant-collapse-content-active {
  background: #af00000d;
}

tr.ant-table-row.ant-table-row-level-0.editable-row .ant-table-cell {
  padding: 8px !important;
}

tr.ant-table-row.ant-table-row-level-0.editable-row .ant-row.ant-form-item {
  display: flow !important;
}

tr.ant-table-row.ant-table-row-level-0.editable-row
  .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16 {
  padding: 0 !important;
  min-width: fit-content !important;
}

.pdfViewer_1 .pdf-viewer-button {
  display: none !important;
}

.investment_planning_wrapper {
  margin-bottom: 15rem;
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 15px 0px #0000000d;
  padding: 16px;
  border-radius: 12px;
}

.aua_New_Steps_Container {
  border-right: 1px solid #e1e3ea;
  height: 100dvh;
}

.aua_New_Steps_Container .menu-wrapper {
  margin-right: 30px;
  margin-left: 30px;
}

@media screen and (max-width: 749px) {
  .aua_New_Steps_Container {
    border-right: none;
    height: 100%;
    border-bottom: 1px solid #e1e3ea;
    width: 100dvh;
  }

  .aua_New_Steps_Container .menu-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    height: auto;
  }

  .risk-profile-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 10px 10px 10px;
  }

  .ant-form-item-control-input-content {
    margin: 1px;
  }

  .gen-extra-container {
    gap: 10px;
  }

  .panel-content div.left {
    color: #858585;
    font-weight: 400;
  }

  .agree-wrapper {
    margin-top: 0px;
  }

  .agree-wrapper.aua-verification {
    margin-top: 20px;
  }

  .footer-btn-div {
    margin-top: 20px;
  }

  .assessment-details {
    margin-bottom: 20px;
  }

  .ant-col.payment_col.ant-col-md-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .editCollapse {
    margin-bottom: 50px;
  }
}
